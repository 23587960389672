:root {
  --color-background: #2f4050;
  --color-border: #7590a9;
  --color-green: #3e7a2a;
  --color-orange: #d7971d;
  --color-red: #ad3131;
  --color-whitegray: #f3f3f4;
  --sm: 600px;
  --md: 900px;
  --lg: 1200px;
  --xl: 1800px;
}

body {
  background-color: var(--color-whitegray);
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  line-height: 1.42857143;
  color: #676a6c;
  transition: all ease .3s;
}
.invisible {
  visibility: hidden;
}
table {
  border-collapse: collapse;
}
table th, table td {
  border: 1px solid var(--color-background);
  border-right: 0;
  border-bottom: 0;
  padding: 5px 18px 3px 9px;
}
table th {
  border-top: 0;
  border-bottom: 2px solid var(--color-background);
}
table tr td:first-child, table tr th:first-child {
  border-left: 0;
}
table tbody tr:first-child td {
  border-top: 0;
}
#LogdNix .btn {
  border-radius: 0;
  box-shadow: none !important;
  outline: 0 !important;
  border: 0;
  border-left: 2px solid #8093a7;
  background-color: white;
  color: var(--color-border);
  padding: 0;
  width: 66px;
  height: 30px;
  display: flex;
}
#LogdNix .btn:not([disabled]):hover {
  background-color: #d2dee8;
}
#LogdNix .btn[disabled] {
  opacity: 0.3;
}
#LogdNix .btn-right {
  border: 0;
  border-right: 2px solid #8093a7;
}
#LogdNix .btn i {
  margin: auto;
}

.btn-red i {
  color: var(--color-red)
}
.btn-green i {
  color: var(--color-green)
}

ul.dropDown {
  position: relative;
  background-color: var(--color-background);
  color: white;
  padding: 0;
  margin: 0;
}
.dropDown li {
  list-style: none;
  border-bottom: 1px solid white;
}
.dropDown li:last-child {
  border-bottom: none
}
.dropDown button {
  all: unset;
  cursor: pointer;
  padding: 0 15px 0 13px !important;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
.dropDown button:not([disabled]):hover {

}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.white-card {
  padding: 15px;
  margin-bottom: 25px;
  background-color: #fff;
}
.btn-default {
  color: inherit;
  background: #fff;
  border: 1px solid #e7eaec;
}

button[disabled] {
  cursor: unset !important;
}
/*noinspection ALL*/
textarea {
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  box-sizing: border-box;
  color: inherit;
  display: block;
  padding: 6px 12px;
  -webkit-transition: border-color .15s ease-in-out 0s,-webkit-box-shadow .15s ease-in-out 0s;
  transition: border-color .15s ease-in-out 0s,-webkit-box-shadow .15s ease-in-out 0s;
  -o-transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
  transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s,-webkit-box-shadow .15s ease-in-out 0s;
  width: 100%;
}
.d-none {
  display: none;
}



ul.button-editBlock {
  display: none !important;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
}
ul.button-editBlock li {
  position: relative;
  border: none;
}
ul.button-editBlock button[disabled] {
  opacity: .4;
}
ul.button-editBlock button:not[disabled]:hover {
  color: var(--color-green);
}

ul.button-editBlock button {
  width: auto;
  padding: 0 7px;
  font-size: 10px;
}
.fileInput {
  display: flex;
}
.fileInput label, .button-export {
  all: unset;
  cursor: unset;
  position: relative;
  display: flex;
  height: auto;
  padding: 13px 20px 10px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  letter-spacing: .008em;
  z-index: 1;
  opacity: .4;
}
.fileInput input:not([disabled]) + label:hover, .button-export:not([disabled]):hover {
  opacity: .6;
  cursor: pointer;
}

#FileInput {
  visibility: hidden;
  width: 0;
}

#EditMode-button {
  position: relative;
  all: unset;
  height: auto;
  padding: 13px 20px 10px;
  align-self: flex-start;
  font-weight: bold;
  color: var(--color-orange);
  cursor: pointer;
  font-size: 12px;
  letter-spacing: .008em;
  z-index: 1;
}
#EditMode-button.active {
  opacity: 1;
  background-color: var(--color-orange);
  color: var(--color-background);
}
#EditMode-button:not([disabled]):hover {
  opacity: .8;
  background-color: var(--color-orange);
  color: var(--color-background);
}
#Content {
  grid-area: content;
  overflow: auto;
  display: grid;
  grid-template-areas: "holdNav" "hold" "newestItems";
  grid-template-rows: 25px 1fr;
}
#Content > .content {
  display: grid;
}
.content-block {
  border-right: 1px solid var(--color-background);
  padding-left: 20px;
  padding-right: 20px;
}
.content-block > .header {
  position: relative;
  display: inline-block;
  padding-right: 32px;
  margin-top: 13px;
}

.col {
  padding: 0 25px 35px;
}

.item p {
  white-space: break-spaces;
}
.item {
  position: relative;
}

#EditThisItem + #EditButtonOptions {
  display: inline-block !important;
}
#EditButtonOptions {
  display: none !important;
}
#ButtonEdit {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
}

#ButtonEdit button[type="edit"] {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff4a;
}

.add-card {
  display: flex;
  min-height: 122px;
  background-color: transparent;
  padding: 0;
}
#Active-form {
  background-color: white;
  margin-top: auto;
  width: 100%;
}
#Active-form .buttons-container {
  background-color: var(--color-whitegray);
}

.ck-toolbar button {
  cursor: pointer !important;
}
.ck-toolbar {
  border:0 !important;
  background-color: white !important;
}
.ck-toolbar__items button:nth-last-child(2) {
  margin-left: auto;
}
.ck-content {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 0 !important;
  border-top: 2px solid var(--color-border) !important;
  border-bottom: 2px solid var(--color-border) !important;
}

#Items-Main .button-addItem {
  margin-bottom: 25px;
}
#Items-Main .items {
  display: flex;
  flex-direction: column-reverse;
}

.buttons-container {
  display: flex;
}

.date-container .date:nth-child(2) {
  margin-left: 12px;
}
.date-container {
  position: absolute;
  right: 2px;
  bottom: 0;
  font-size: 0.8rem;
  display: flex;
}


#EditBlock {
  display: flex;
  align-items: center;
}
#LogdNix #EditBlock button:not([disabled]):hover {
  cursor: pointer;
  background-color: #8da3b7;
}
#LogdNix #EditBlock button.btn {
  all: unset;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  height: 40px;
  background: white;
  color: var(--color-green);
  border-left: 1px solid var(--color-background);
}
#LogdNix #EditBlock button:last-child {
  color: var(--color-red);
}
#EditBlock input {
  all: unset;
  background: white;
  height: 40px;
  margin: 5px 0 5px 13px;
  padding: 0 8px;
  width: 150px;
}

@media (max-width: 900px) {
  #LogdNix {
    grid-template-columns: 200px 1fr !important;
  }
}
@media (max-width: 600px) {
  #LogdNix {
    grid-template-columns: 38px 1fr !important;
  }
}
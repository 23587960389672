
#ControlCenter * {
    transition: unset !important;
}
#ControlCenter {
    position: relative;
    height: 24px;
    box-sizing: border-box;
    padding-top: 4px;
    overflow: hidden;
    border-top: 1px solid var(--color-background);
    background-color: var(--color-background);
    color: var(--color-whitegray);
}
#ControlCenter.active {
    background-color: var(--color-whitegray);
    color: var(--color-background);
}
